const BaseService = require('@/services/BaseService')

const namespace = 'integrations/service-notes'

const find = (id, options, cb, errorCb) => {
  BaseService.get(`${namespace}/${id}`, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
  BaseService.get(namespace, options, cb, errorCb)
}

const create = (data, cb, errorCb) => {
    BaseService.post(namespace, data, cb, errorCb)
}

const put = (id, data, cb, errorCb) => {
    BaseService.put(`${namespace}/${id}`, data, cb, errorCb)
}

const remove = (id, cb, errorCb) => {
    BaseService.remove(`${namespace}/${id}`, {}, cb, errorCb)
}

export {
  find,
  findAll,
  create,
  put,
  remove
}
